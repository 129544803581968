import env from './env';

export const { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } = env;

const APP_ID = 'corporate';

const LIMIT_PER_PAGE = 1000;

export const ENDPOINTS = {
    ENQUIRY: `${API_BASE_URL}/${APP_ID}/get-in-touch`,
    NOTIFICATIONS: `${API_BASE_URL}/${APP_ID}/notifications`,
    PAGES: (slug, parent = '') => `${API_BASE_URL}/${APP_ID}/pages?slug=${encodeURIComponent(slug)}&parent=${encodeURIComponent(parent)}`,
    PAGES_ALL: `${API_BASE_URL}/${APP_ID}/pages?limit=${LIMIT_PER_PAGE}`,
    TOKEN: `${API_BASE_URL}/oauth/token`,
    SEARCH: `${API_BASE_URL}/${APP_ID}/search`,
    CUSTOM_URLS: `${API_BASE_URL}/${APP_ID}/custom-urls`,
    SITEMAP: `${API_BASE_URL}/${APP_ID}/sitemap`,
    NEWS: (includeFeatured = true, page = 1, category = null) => {
        let requestURL = `${API_BASE_URL}/${APP_ID}/news?is_published=true`;

        // API is weird so need both of these exceptions
        if (includeFeatured) requestURL += '&include=featured';
        if (!includeFeatured) requestURL += '&featured=false';

        if (category) requestURL += `&category=${encodeURIComponent(category)}`;
        if (page) requestURL += `&page=${encodeURIComponent(page)}`;

        return requestURL;
    },
    NEWS_PAGE: (slug) => `${API_BASE_URL}/${APP_ID}/news?slug=${slug}`,
    NEWS_CATEGORIES: `${API_BASE_URL}/${APP_ID}/news-categories`,
};

export const RESPONSE = {
    OK: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};

export const TIMEOUT = 0;
